<template>
  <div class="mk-index booking-bg container-fluid full-bg">
    <div class="container">
      <div class="row py-3">
        <div class="col-12">
          <h1 class="text-center">let's get started</h1>
        </div>
      </div>

      <div class="row py-3">
        <div class="col-12 col-lg-6 mb-3 mb-lg-0">
          <div class="fe-card card landing-card">
            <div class="row">
              <div class="col-12 d-flex flex-column justify-content-between">
                <div>
                  <h3 class="mb-4 text-center">start with a date</h3>
                  <p class="text-center mb-0">
                    Already have a date for your wedding in mind? Select a date
                    and look for available locations.
                  </p>
                </div>

                <div class="">
                  <!-- <input class="mb-4 " /> -->
                  <el-date-picker
                    class="w-100 mb-4"
                    format="dd/MM/yyyy"
                    placeholder="Select a date"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    v-model="query.date"
                  >
                  </el-date-picker>
                  <el-button class="btn-gold btn-wide" @click="searchByDate"
                    >Search</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="fe-card card landing-card">
            <div class="row">
              <div class="col-12 d-flex flex-column justify-content-between">
                <div>
                  <h3 class="mb-4 text-center">start with a location</h3>
                  <p class="text-center mb-0">
                    Have your heart set on one of our amazing locations? Select
                    the ceremony location and look for available booking dates.
                  </p>
                </div>

                <div>
                  <el-autocomplete
                    v-model="query.venue_search"
                    :fetch-suggestions="searchVenue"
                    placeholder="Search venue"
                    class="w-100 mb-4"
                    @select="handleSelect"
                  >
                    <template slot-scope="{ item }">
                      <div>
                            {{ item.name }}
                      </div>
                    </template>
                  </el-autocomplete>

                  <el-button class="btn-gold btn-wide">Search</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<style scoped lang="scss">
h1 {
  margin-top: 160px;
  margin-bottom: 60px;

  @media screen and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.landing-card {
  padding: 56px 66px 68px;

  @media screen and (max-width: 992px) {
    padding: 30px;
  }

  @media screen and (max-width: 576px) {
    padding: 15px;
  }

  @media screen and (max-width: 992px) {
  }

  > .row {
    height: 245px;

    @media screen and (max-width: 992px) {
      height: 260px;
    }

    input,
    .btn-gold {
      width: 100%;
    }
  }
}
</style>



<script>
import Venue from "~/models/Venue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      query: {
        date: dayjs()
          .minute(0)
          .hour(0)
          .second(0)
          .millisecond(0)
          .add(31, "day")
          .format("YYYY-MM-DD"),
        venue_search: null,
      },
      pickerOptions: {
        disabledDate(time) {
          return (
            (time.getTime() <
            dayjs()
              .minute(0)
              .hour(0)
              .second(0)
              .millisecond(0)
              .add(31, "day")
              .valueOf())
            ||
            ( time.getTime() >
              dayjs()
                .add(18, "month")
                .valueOf()
            )
          );
        },
      },
    };
  },
  methods: {
    async searchVenue(query, cb) {
      try {
        const { data } = await Venue.where("where_like", query).page(1).get();

        cb(data);
      } catch (err) {
        console.error(err);
      }
    },
    handleSelect(item) {
      this.$router.push({ name: "venues", query: { venue_id: item.id } });
    },
    searchByDate() {
      this.$router.push({ name: "venues", query: { date: this.query.date } });
    },
  },
};
</script>
