import {
  Model as BaseModel
} from "vue-api-query";

export default class Model extends BaseModel {
  // define a base url for a REST API
  baseURL() {
    return `${process.env.NUXT_ENV_CORE_APP_DOMAIN}/api/v1/customer`;
  }

  // implement a default request method
  request(config) {
    return this.$http.request(config);
  }
}
